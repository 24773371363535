var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ax-card',[_c('v-card-title',{staticClass:"tw-flex tw-justify-between tw-uppercase"},[_c('span',{attrs:{"data-test-id":"compatibility-checklist-header"}},[_vm._v("Compatibility Checklist")]),_c('ax-button',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close-modal')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiClose))])],1)],1),_c('v-card-text',[_c('h4',{staticClass:"tw-font-bold tw-mb-1 tw-text-base"},[_vm._v("Agent Requirements:")]),_c('ul',{staticClass:"tw-mb-4"},[(_vm.deviceOs === 'windows')?[_c('li',{staticClass:"tw-flex tw-items-center"},[(
              _vm.device &&
              _vm.device.compatibility_checks &&
              !_vm.device.compatibility_checks.missing_powershell
            )?_c('v-icon',{staticClass:"tw-mr-2",attrs:{"color":"success","small":""}},[_vm._v(_vm._s(_vm.mdiCheckCircle))]):_c('v-icon',{staticClass:"tw-mr-2",attrs:{"color":"error","small":""}},[_vm._v(_vm._s(_vm.mdiCloseCircle))]),_c('span',[_vm._v("PowerShell Installed")])],1),_c('li',{staticClass:"tw-flex tw-items-center"},[(
              _vm.device &&
              _vm.device.compatibility_checks &&
              !_vm.device.compatibility_checks.missing_wmi_integrity_check
            )?_c('v-icon',{staticClass:"tw-mr-2",attrs:{"color":"success","small":""}},[_vm._v(" "+_vm._s(_vm.mdiCheckCircle)+" ")]):_c('v-icon',{staticClass:"tw-mr-2",attrs:{"color":"error","small":""}},[_vm._v(_vm._s(_vm.mdiCloseCircle))]),_c('span',[_vm._v("WMI Check")])],1)]:_vm._e(),_c('li',{staticClass:"tw-flex tw-items-center"},[(
            _vm.device &&
            _vm.device.compatibility_checks &&
            !_vm.device.compatibility_checks.low_diskspace
          )?_c('v-icon',{staticClass:"tw-mr-2",attrs:{"color":"success","small":""}},[_vm._v(_vm._s(_vm.mdiCheckCircle))]):_c('v-icon',{staticClass:"tw-mr-2",attrs:{"color":"error","small":""}},[_vm._v(_vm._s(_vm.mdiCloseCircle))]),_c('span',[_vm._v("3GB Of Disk Space Free")])],1),(
          _vm.deviceOs === 'mac' &&
          _vm.device &&
          _vm.device.compatibility_checks &&
          typeof _vm.device.compatibility_checks.missing_secure_token !==
            'undefined'
        )?_c('li',{staticClass:"tw-flex tw-items-center"},[(!_vm.device.compatibility_checks.missing_secure_token)?_c('v-icon',{staticClass:"tw-mr-2",attrs:{"color":"success","small":""}},[_vm._v(_vm._s(_vm.mdiCheckCircle))]):_c('v-icon',{staticClass:"tw-mr-2",attrs:{"color":"error","small":""}},[_vm._v(_vm._s(_vm.mdiCloseCircle))]),_c('span',[_vm._v(" This Mac device requires "),_c('a',{attrs:{"href":"https://help.automox.com/hc/en-us/articles/5352197527956-Install-and-Configure-Automox-Agent-for-Apple-Silicon-Devices","target":"_blank"}},[_vm._v(" Secure Token User Permission ")])])],1):_vm._e()],2),(_vm.deviceOs !== 'linux')?_c('h4',{staticClass:"tw-font-bold tw-mb-1 tw-text-base"},[_vm._v(" Connectivity to Patch Source: ")]):_vm._e(),_c('ul',[(_vm.deviceOs === 'mac')?_c('li',{staticClass:"tw-flex tw-items-center"},[(
            _vm.device &&
            _vm.device.compatibility_checks &&
            !_vm.device.compatibility_checks.app_store_disconnected
          )?_c('v-icon',{staticClass:"tw-mr-2",attrs:{"color":"success","small":""}},[_vm._v(_vm._s(_vm.mdiCheckCircle))]):_c('v-icon',{staticClass:"tw-mr-2",attrs:{"color":"error","small":""}},[_vm._v(_vm._s(_vm.mdiCloseCircle))]),_c('span',[_vm._v("Mac App Store")])],1):_vm._e(),(_vm.deviceOs === 'windows' && _vm.isWSUSManaged())?[_c('li',{staticClass:"tw-flex tw-items-center"},[(
              _vm.device &&
              _vm.device.compatibility_checks &&
              !_vm.device.compatibility_checks.wsus_disconnected
            )?_c('v-icon',{staticClass:"tw-mr-2",attrs:{"color":"success","small":""}},[_vm._v(_vm._s(_vm.mdiCheckCircle))]):_c('v-icon',{staticClass:"tw-mr-2",attrs:{"color":"error","small":""}},[_vm._v(_vm._s(_vm.mdiCloseCircle))]),_c('span',[_vm._v(_vm._s(_vm.device && _vm.device.compatibility_checks && _vm.device.compatibility_checks.wsus_disconnected ? 'Server unreachable. Please check connection and try again.' : ("WSUS Update Server - " + (_vm.device && _vm.device.detail.WSUS_CONFIG.WSUS_SERVER))))])],1)]:_vm._e(),(_vm.deviceOs === 'windows' && !_vm.isWSUSManaged())?_c('li',{staticClass:"tw-flex tw-items-center"},[(
            _vm.device &&
            _vm.device.compatibility_checks &&
            !_vm.device.compatibility_checks.windows_update_server_disconnected
          )?_c('v-icon',{staticClass:"tw-mr-2",attrs:{"color":"success","small":""}},[_vm._v(" "+_vm._s(_vm.mdiCheckCircle)+" ")]):_c('v-icon',{staticClass:"tw-mr-2",attrs:{"color":"error","small":""}},[_vm._v(_vm._s(_vm.mdiCloseCircle))]),_c('span',[_vm._v("Windows Update Server")])],1):_vm._e()],2)]),_c('v-card-actions',{staticClass:"tw-justify-end tw-px-6 tw-pb-6"},[_c('ax-button',{attrs:{"mode":"secondary"},on:{"click":function($event){return _vm.$emit('close-modal')}}},[_vm._v("Close")]),_c('ax-button',{attrs:{"data-test-id":"compatibility-check-device-btn","loading":_vm.submitting,"mode":"primary"},on:{"click":_vm.checkDevice}},[_vm._v(" Check Device ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }