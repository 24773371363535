var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ax-tile',{staticClass:"tw-h-full",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h2',{staticClass:"section-title"},[_vm._v("Associated Policies")])]},proxy:true},{key:"content",fn:function(){return [_c('ax-table',{attrs:{"headers":_vm.headers,"items":_vm.policiesForDevice,"column-select":false,"footer-props":{ 'items-per-page-options': [10, 20, -1] },"item-key":"id","no-data-text":"No policies","sort-by":"next_patch_window","keep-in-view":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 0)?_c('v-icon',{attrs:{"color":"error","title":"Needs Attention","dense":""}},[_vm._v(_vm._s(_vm.mdiAlert))]):_vm._e(),(item.status === 1)?_c('v-icon',{attrs:{"color":"success","title":"Compliant","dense":""}},[_vm._v(_vm._s(_vm.mdiCheckCircle))]):_vm._e(),(item.status === 2)?_c('v-icon',{attrs:{"color":"teal","title":"Pending","dense":""}},[_vm._v(_vm._s(_vm.mdiProgressClock))]):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"title":item.name,"to":{
            name: _vm.PolicyRouteNames.PolicyForm,
            params: { policyId: item.id },
            query: {
              o: item.organization_id,
              frompage: ("/devices/" + (_vm.device.id)),
            },
          }}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"policy-type"},[_vm._v(_vm._s(item.policy_type))])]}},{key:"item.next_patch_window",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.next_patch_window || 'Off')+" ")]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('ax-button',{attrs:{"small":"","mode":"secondary"},on:{"click":function($event){return _vm.openModal(item.id)}}},[_vm._v("Run on this device")])]}}])}),(_vm.deviceGroup)?_c('ax-button',{staticClass:"tw-mt-4",attrs:{"data-test-id":"manage-policies-group","to":{
        name: 'groupEditor',
        query: {
          o: _vm.device.organization_id,
          frompage: ("/devices/" + (_vm.device.id)),
        },
        params: {
          groupId: _vm.device.server_group_id,
        },
      },"mode":"secondary","block":""}},[_vm._v(" Manage policies of the "+_vm._s(_vm.deviceGroup.name || 'Default')+" group ")]):_vm._e()]},proxy:true}])},[_c('ax-modal',{on:{"input":function($event){!$event && _vm.resetModal()}},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('run-policy-modal',{attrs:{"device":_vm.device,"policy-id":_vm.selectedPolicyId},on:{"close-modal":function($event){_vm.showModal = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }