var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ax-tile',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h2',{staticClass:"section-title"},[_vm._v("Software")])]},proxy:true},{key:"content",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"tw-py-0",attrs:{"cols":"12","md":"6"}},[_c('ax-combobox',{staticClass:"mb-0",attrs:{"value":_vm.filters,"items":_vm.filterSuggestions,"prepend-inner-icon":_vm.mdiMagnify,"append-icon":"","placeholder":"Search by Name, Status and Severity","data-test-id":"device-software-search-input","clearable":"","deletable-chips":"","multiple":"","chips":""},on:{"change":_vm.onSearchTermAdded}})],1)],1),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"flex items-center tw-mb-5",attrs:{"cols":"auto"}},[_c('software-bulk-actions',{attrs:{"eligible-software":_vm.eligibleSoftware},on:{"open-modal":_vm.openModal}})],1)],1),_c('ax-table',{attrs:{"forced-sort":false,"column-select":false,"footer-props":_vm.deviceSoftwareFooter,"headers":_vm.deviceSoftwareHeaders,"items":_vm.software,"search":_vm.search,"custom-filter":function (value, term, item) { return _vm.useMultiFilterTableSearch(
            value,
            term,
            ((item.name) + " " + (item.display_name) + " " + (item.status) + " " + (item.severity))
          ); },"sort-by":_vm.tableSort.sortBy,"sort-desc":_vm.tableSort.sortDesc,"page":_vm.softwareTablePage,"item-key":"id","loading-text":"Loading software...","no-data-text":"No software currently available","multi-sort":"","show-select":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.tableSort, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.tableSort, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.tableSort, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.tableSort, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"item.current_version",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"tw-capitalize"},[_vm._v(_vm._s(item.current_version ? item.current_version : '-'))])]}},{key:"item.latest_version",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"tw-capitalize"},[_vm._v(_vm._s(item.latest_version ? item.latest_version : '-'))])]}},{key:"item.severity",fn:function(ref){
          var item = ref.item;
          var value = ref.value;
return [_c('ax-cve-tooltip',{attrs:{"cves":item.cves,"label":value}})]}},{key:"item.needs_reboot",fn:function(ref){
          var item = ref.item;
return [(item.needs_reboot)?_c('div',{staticClass:"tw-inline-flex tw-items-center"},[_c('v-icon',{attrs:{"color":"error","title":"Requires Reboot","small":""}},[_vm._v(_vm._s(_vm.mdiRestartAlert))]),_c('span',{staticClass:"tw-ml-2"},[_vm._v("Yes")])],1):_vm._e()]}}]),model:{value:(_vm.selectedSoftware),callback:function ($$v) {_vm.selectedSoftware=$$v},expression:"selectedSoftware"}})]},proxy:true}])},[_c('ax-modal',{model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(_vm.activeModal === 'rollback')?_c('rollback-modal',{attrs:{"device":_vm.device,"software":_vm.eligibleSoftware.canRollback},on:{"close-modal":_vm.closeModal,"batch-action-success":function($event){_vm.selectedSoftware = []}}}):_vm._e(),(_vm.activeModal === 'patchNow')?_c('patch-modal',{attrs:{"device":_vm.device,"software":_vm.eligibleSoftware.canPatch},on:{"close-modal":_vm.closeModal,"batch-action-success":function($event){_vm.selectedSoftware = []}}}):_vm._e(),(_vm.activeModal === 'defer')?_c('defer-modal',{attrs:{"device":_vm.device,"software":_vm.eligibleSoftware.canDefer},on:{"close-modal":_vm.closeModal,"batch-action-success":function($event){return _vm.softwareUpdated()}}}):_vm._e(),(_vm.activeModal === 'ignore')?_c('ignore-modal',{attrs:{"device":_vm.device,"software":_vm.eligibleSoftware.canIgnore},on:{"close-modal":_vm.closeModal,"batch-action-success":function($event){return _vm.softwareUpdated()}}}):_vm._e(),(_vm.activeModal === 'useGlobalSettings')?_c('global-settings-modal',{attrs:{"device":_vm.device,"software":_vm.eligibleSoftware.canInheritGlobal},on:{"close-modal":_vm.closeModal,"batch-action-success":function($event){return _vm.softwareUpdated()}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }