import { GroupedDeviceSoftware } from '@ax/data-services-devices/models';

export function getPackageArgs(software: GroupedDeviceSoftware[]): string {
  return software
    .reduce((acc, { name }) => {
      if (name) {
        return `${acc} ${name.includes(' ') ? `"${name}"` : name}`;
      }
      return acc;
    }, ``)
    .trim();
}

export function getPackageNames(software: GroupedDeviceSoftware[]): string {
  return software.reduce(
    (acc, { package_name }) => `${acc}${package_name || ''}, `,
    '',
  );
}
