var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ax-tile',{staticClass:"ax-device-info tw-h-full",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit(_vm.$v)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h2',{staticClass:"section-title"},[_vm._v("Info")])]},proxy:true},{key:"content",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"tw-py-0",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"tw-flex",attrs:{"data-test-id":"host-name"}},[_c('label',{staticClass:"text-field-label",attrs:{"for":"hostName"}},[_vm._v("Host Name")]),_c('div',{staticClass:"Info__name",attrs:{"id":"hostName"}},[_vm._v(_vm._s(_vm.device.name))])])]),_c('v-col',{staticClass:"tw-py-0",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"tw-flex",attrs:{"data-test-id":"group"}},[_c('label',{staticClass:"text-field-label",attrs:{"for":"group"}},[_vm._v("Group")]),_c('ax-autocomplete',{attrs:{"id":"group","items":_vm.groups,"error-messages":_vm.groupError,"required":"","clearable":""},on:{"blur":function($event){return _vm.$v.deviceForm.group.$touch()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-flex tw-items-center tw-w-full",style:(("margin-left: " + (0.25 * 6 * item.depth) + "rem"))},[_c('v-icon',{staticClass:"tw-flex-shrink-0 tw-mr-1 tw-pr-1",attrs:{"color":item.ui_color,"x-small":""}},[_vm._v(_vm._s(_vm.mdiCircle))]),_c('span',[_vm._v(_vm._s(item.text))])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-flex tw-items-center"},[_c('v-icon',{staticClass:"tw-flex-shrink-0 tw-mr-1 tw-pr-1",attrs:{"color":item.ui_color,"x-small":""}},[_vm._v(_vm._s(_vm.mdiCircle))]),_c('span',[_vm._v(_vm._s(item.text))])],1)]}}]),model:{value:(_vm.deviceForm.group),callback:function ($$v) {_vm.$set(_vm.deviceForm, "group", $$v)},expression:"deviceForm.group"}})],1)])],1),_c('v-row',[_c('v-col',{staticClass:"tw-pt-0",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"tw-flex",attrs:{"data-test-id":"custom-name"}},[_c('label',{staticClass:"text-field-label",attrs:{"for":"customName"}},[_vm._v("Custom Name")]),_c('ax-text-field',{attrs:{"id":"customName","autocomplete":"off","placeholder":"Device Custom Name"},model:{value:(_vm.deviceForm.customName),callback:function ($$v) {_vm.$set(_vm.deviceForm, "customName", $$v)},expression:"deviceForm.customName"}})],1)]),_c('v-col',{staticClass:"tw-pt-0",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"tw-flex",attrs:{"data-test-id":"tags"}},[_c('label',{staticClass:"text-field-label",attrs:{"for":"tags"}},[_vm._v("Tags")]),_c('div',{staticClass:"tw-min-w-0"},[_c('ax-device-tags-combobox',{attrs:{"id":"tags"},model:{value:(_vm.deviceForm.tags),callback:function ($$v) {_vm.$set(_vm.deviceForm, "tags", $$v)},expression:"deviceForm.tags"}})],1)])])],1),_c('v-row',[_c('v-col',{staticClass:"tw-pt-0",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"tw-flex"},[_c('label',{staticClass:"text-field-label text-field-label--normal",attrs:{"for":"excludeFromReport"}},[_vm._v(" Exclude From "),_c('span',{staticClass:"tw-whitespace-no-wrap"},[_vm._v(" Reports "),_c('ax-tooltip',{attrs:{"max-width":"16rem","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ax-button',{staticClass:"tw-ml-px",attrs:{"aria-label":"What does 'exclude from report' mean?","icon":"","x-small":""},on:{"click":on.mouseenter,"focus":on.mouseenter,"mouseenter":on.mouseenter,"mouseleave":on.mouseleave,"blur":on.mouseleave}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(_vm._s(_vm.mdiInformationOutline))])],1)]}}])},[_vm._v(" If toggled on, the device is excluded from reporting and statistics but is still monitored. ")])],1)]),_c('ax-toggle',{staticClass:"tw-mt-1 mb-0",attrs:{"id":"excludeFromReport","hide-details":""},model:{value:(_vm.deviceForm.exception),callback:function ($$v) {_vm.$set(_vm.deviceForm, "exception", $$v)},expression:"deviceForm.exception"}})],1)]),_c('v-col',{staticClass:"tw-pb-0",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"tw-flex"},[_c('div',{staticClass:"text-field-label label-without-input"},[_vm._v(" Last User Logged In ")]),_c('div',[(_vm.device.detail && _vm.device.detail.LAST_USER_LOGON)?_c('div',[_vm._v(" "+_vm._s(_vm.device.detail.LAST_USER_LOGON.USER)+" ")]):_vm._e(),(_vm.device.detail && _vm.device.detail.LAST_USER_LOGON)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(_vm.device.detail.LAST_USER_LOGON.TIME))+" ")]):_vm._e(),(
                !_vm.device.detail ||
                (!_vm.device.detail.LAST_USER_LOGON &&
                  !_vm.device.detail.LAST_USER_LOGON)
              )?_c('div',[_vm._v(" — ")]):_vm._e()])])])],1),_c('v-row',[_c('v-col',{staticClass:"tw-pb-0",attrs:{"cols":"12","md":"6"}},[_c('system-details',{attrs:{"device":_vm.device}})],1),_c('v-col',{staticClass:"tw-pb-0",attrs:{"cols":"12","md":"6"}},[_c('network-details',{attrs:{"device":_vm.device}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"tw-pb-0",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"tw-flex"},[_c('div',{staticClass:"text-field-label label-without-input"},[_vm._v(" Automox Agent Version ")]),_c('span',[_vm._v(_vm._s(_vm.device.agent_version || '—'))])])]),_c('v-col',{staticClass:"tw-pb-0",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"tw-flex",attrs:{"data-test-id":"organizational-unit"}},[_c('div',{staticClass:"text-field-label label-without-input"},[_vm._v(" Active Directory Organizational Unit ")]),_c('ax-device-organizational-unit',{attrs:{"org-unit":_vm.device.organizational_unit}})],1)])],1),_c('div',{staticClass:"tw-flex tw-justify-end"},[(_vm.formReady)?_c('ax-confirm-cancel',{staticClass:"tw-mt-6",attrs:{"show-cancel":false,"submit-label":'Update Device',"disable-submit":_vm.$v.$invalid,"type":"submit"},model:{value:(_vm.deviceForm),callback:function ($$v) {_vm.deviceForm=$$v},expression:"deviceForm"}}):_vm._e()],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }