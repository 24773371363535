import {
  GroupedDeviceSoftware,
  Status,
} from '@ax/data-services-devices/models';
import { Severity, SEVERITY_ORDER } from '@ax/data-services-software';
import { DeviceSoftware } from '@/models/devices';
import { formatDate, isDateAfter } from '@/utils/date-time';
import { groupBy } from '@/utils/util';

const groupByPackageId = (
  software: DeviceSoftware[],
): GroupedDeviceSoftware[] => {
  const groupedPackages = groupBy(software, 'name');

  return Object.keys(groupedPackages).map((name) => {
    const packages = groupedPackages[name];

    if (packages.length > 1) {
      const current = packages.find((pkg) => pkg.installed) as DeviceSoftware;
      const latest = packages.find((pkg) => !pkg.installed) as DeviceSoftware;

      if (latest) {
        return {
          ...latest,
          current_version:
            current && current.version && current.repo !== 'WindowsUpdate'
              ? current.version
              : '',
          data: latest,
          latest_version:
            latest && latest.repo === 'WindowsUpdate' ? '' : latest.version,
          needs_reboot: latest.requires_reboot && !latest.installed,
          package_name: latest.display_name || latest.name,
          severity:
            !latest.severity || latest.severity === Severity.other
              ? Severity.unknown
              : latest.severity,
        };
      }
    }

    const current = packages[0] as DeviceSoftware;

    return {
      ...current,
      current_version:
        current.repo === 'WindowsUpdate' || !current.installed
          ? ''
          : current.version,
      data: current,
      latest_version: !current.installed ? current.version : '',
      needs_reboot: current.requires_reboot && !current.installed,
      package_name: current.display_name || current.name,
      severity:
        !current.severity || current.severity === Severity.other
          ? Severity.unknown
          : current.severity,
    };
  });
};

const setStatus = (software: GroupedDeviceSoftware): GroupedDeviceSoftware => {
  if (software.installed) {
    return { ...software, status: Status.installed };
  }

  if (software.ignored || software.group_ignored) {
    return { ...software, status: Status.ignored };
  }

  if (software.deferred_until && isDateAfter(software.deferred_until)) {
    return {
      ...software,
      status: `${Status.deferredUntil} ${formatDate(software.deferred_until)}`,
    };
  }

  if (
    software.group_deferred_until &&
    isDateAfter(software.group_deferred_until)
  ) {
    return {
      ...software,
      status: `${Status.deferredUntil} ${formatDate(
        software.group_deferred_until,
      )}`,
    };
  }

  return { ...software, status: Status.awaitingUpdate };
};

const sortDeviceSoftware = (a, b) => {
  const aSeverity = SEVERITY_ORDER.indexOf(a.severity);
  const bSeverity = SEVERITY_ORDER.indexOf(b.severity);

  if (aSeverity < bSeverity) {
    return 1;
  }
  if (aSeverity > bSeverity) {
    return -1;
  }

  return a.display_name.localeCompare(b.display_name);
};

const groupDeviceSoftware = (
  software: DeviceSoftware[] = [],
): GroupedDeviceSoftware[] =>
  groupByPackageId(software).map(setStatus).sort(sortDeviceSoftware);

const sortBySeverity = (a, b) => {
  const aSeverity = SEVERITY_ORDER.indexOf(a);
  const bSeverity = SEVERITY_ORDER.indexOf(b);

  if (aSeverity > bSeverity) {
    return 1;
  }
  if (aSeverity < bSeverity) {
    return -1;
  }

  return 0;
};

export { groupDeviceSoftware, sortBySeverity };
